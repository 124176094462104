"use client"
import * as React from 'react';
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '@/components/AppAppBar';
import Footer from '@/components/Footer';
import getTheme from '@/components/getTheme';
import Roadmap from '@/components/Roadmap';
import Tokenomics from '@/components/Tokenomics';
import Wilcome from '@/components/Wilcome';
import Community from '@/components/Community';
import Lottery from '@/components/Lottery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CorporatePartners from "@/components/CorporatePartners";


export default function Home() {
    const [mode, setMode] = React.useState<PaletteMode>('dark');
    const [showCustomTheme, setShowCustomTheme] = React.useState(true);
    const LPtheme = createTheme(getTheme(mode));
    const defaultTheme = createTheme({ palette: { mode } });
    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

    const toggleCustomTheme = () => {
        setShowCustomTheme((prev) => !prev);
    };

  return (
      <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
          <CssBaseline />
          <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />

          <Box sx={{ bgcolor: 'background.default' }}>
              <br /><br />
              <Wilcome />
              <Divider />
              <CorporatePartners />
              <Divider />
              <Roadmap />
              <Divider />
              <Community />
              <Divider />
              <Footer />
          </Box>
      </ThemeProvider>

  );
}
