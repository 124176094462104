"use client"
import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Grid, Button, IconButton, ButtonGroup, Collapse, Tooltip as MuiTooltip } from '@mui/material';
import Divider from "@mui/material/Divider";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ClientOnly from '@/app/clientOnly';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Image from 'next/image';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import AnimatedNumber from "@/components/AnimatedNumber";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps} from "recharts";

interface CustomPayload {
    name: string;
    value: number;
}

interface ProjectDataResponse {
    rpcURL: string;
    burnLogData: {
        burnsum: number;
        time: number | null;
    };
    extendedLotteryData: {
        next_draw: number;
        pool_volume: string;
        LotteryStarkingVolumeWithoutDecimals: string;
        LotteryStarkingVolumeFormatted: string;
        ActiveLotteryTickets: string;
    };
    lotteryPeriodData: {
        period: number;
        time: number;
        nummbers: string;
        "0_current_numbers": number;
        "1_current_numbers": number;
        "2_current_numbers": number;
        "3_current_numbers": number;
        "4_current_numbers": number;
        "5_current_numbers": number;
        "6_current_numbers": number;
        "2_procent": string;
        "4_procent": string;
        "14_procent": string;
        "60_procent": string;
    };
    tokenData: {
        total_circulating: string;
        total_supply: string;
        totalSupplyWithoutDecimals: string;
        totalSupplyFormatted: string;
        tokenName: string;
        tokenSymbol: string;
        tokenDecimals: string;
        tokenBurnRate: string;
        burnedSupply: number;
        burnedSupplyFormatted: string;
        burnedSupplyPercentFormatted: number;
    };
}

const Wilcome = () => {
    const [projectData, setProjectData] = useState<ProjectDataResponse | null>(null);

    const [openInfo, setOpenInfo] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const theme = useTheme();

    const formatUnixTimestampToUTC = (timestamp: number | null | undefined): string => {
        if (!timestamp) return ''; // Gibt einen leeren String zurück, wenn der Timestamp null oder undefined ist

        const date = new Date(timestamp * 1000); // Unix-Timestamp in Millisekunden umwandeln
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'UTC',
            timeZoneName: 'short'
        };
        return date.toLocaleString('en-US', options).replace('UTC', 'UTC'); // "UTC" als Zeitzone sicherstellen
    };

    const fetchProjectData = async (): Promise<ProjectDataResponse | null> => {
        try {
            const response = await fetch('/api/project-data/');

            if (!response.ok) {
                console.error(`Network response was not OK: ${response.status} ${response.statusText}`);
                return null;
            }

            const data: ProjectDataResponse = await response.json();
            return data;

        } catch (error) {
            console.error('Failed to fetch project data:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ProjectData = await fetchProjectData();
                setProjectData(ProjectData);
            } catch (error) {
                console.error('Error fetching pool volume:', error);
            }
        };

        void fetchData(); // Signalisiert, dass der Rückgabewert des Promises bewusst ignoriert wird
    }, []);


    const handleToggle = () => {
        setOpenInfo(!openInfo);
    };

    const { enqueueSnackbar } = useSnackbar();

    const contract_address = '0xDE373AE127A11E756A9D1cc7743816928B239283';
    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(contract_address);
            enqueueSnackbar('Contract Address copied to clipboard', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            });
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }

    };

    const formatWflrSum = (sum: any) => {
        if (typeof sum !== 'string') return '';

        const number = parseFloat(sum.replace(',', '.')); // Komma in Punkt umwandeln und in Gleitkommazahl konvertieren

        return number.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR', // Ein beliebiger unterstützter Code (z.B. EUR) verwenden
            currencyDisplay: 'code',
            maximumFractionDigits: 0,
            useGrouping: true
        }).replace('EUR', 'WFLR'); // Ersetze "EUR" durch "WFLR"
    };

    const formatDaxSum = (burnsum:  any) => {
        if (typeof burnsum !== 'string') return '';
        const number = parseFloat(burnsum.replace('.', ',')); // Komma in Punkt umwandeln und in Gleitkommazahl konvertieren
        return number.toLocaleString('de-DE', { style: 'currency', currency: 'DAX', maximumFractionDigits: 0, useGrouping: true });
    };

    const formatDate = (dateString: number | null | undefined): string => {
        if (dateString == null) return ''; // Gibt einen leeren String zurück, wenn dateString null oder undefined ist

        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize); // Check on resize

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const LotteryDataArray = [
        { id: 0, value: 60, label: '6 correct numbers' },
        { id: 1, value: 14, label: '5 correct numbers' },
        { id: 2, value: 4, label: '4 correct numbers' },
        { id: 3, value: 2, label: '3 correct numbers' },
        { id: 4, value: 12, label: 'NFT Bonus' },
        { id: 5, value: 8, label: 'Base' },
    ];
    const LotteryDataCOLORS = ['#6140c4', '#218fc9', '#6bdfa8', '#c395e0', '#ffe955', '#e82565'];

    const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
        if (active && payload && payload.length) {
            const value = (payload[0] as CustomPayload).value; // Typ-Assertion auf CustomPayload
            return (
                <div className="custom-tooltip">
                    <p>{`${value}%`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <ClientOnly>
        <Box
            id="wilcome"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
            }}
        >

            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '100%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Box
                        sx={{
                            p: 2
                        }}
                    >
                        <Typography component="h1" variant="h1" color="text.primary">
                            Welcome to Flare DAX!
                        </Typography>

                        <Typography variant="body1" color="text.secondary" sx={{ py: 2 }}>
                            DAX is a Memecoin leveraging the unique potential of the Flare Network to create a balanced and secure token economy. It features a lottery system where users can stake DAX tokens for a chance to win rewards, ensuring the tokens remain out of circulation and maintaining price stability.
                        </Typography>

                        <Typography variant="body1" color="text.secondary" sx={{ py: 2 }}>
                            Lottery draws occur biweekly via a smart contract that generates random winning numbers, with rewards distributed in WFLR to winners holding 3, 4, 5, or 6 matching numbers. The smart contract transparently manages ticket stakes and prize distribution, ensuring fairness and preventing manipulation.
                        </Typography>

                    </Box>

                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            textAlign: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>

                            <Typography variant="h6" sx={{ mx: 2, cursor: 'pointer' }} onClick={handleToggle} >
                                <ArrowDropDownIcon />The DAX memecoin project stands out from other memecoins due to several key features<ArrowDropDownIcon />
                            </Typography>

                        </Box>
                        <Collapse in={openInfo}>
                            <Typography variant="body1" sx={{ textAlign: 'left' }} color="text.secondary">
                                1. *Prevention of Pump & Dump*: DAX has measures in place to avoid the common pump and dump issues seen in other memecoins. The development team&apos;s tokens are locked for a year, and there is no immediate selling of large quantities of tokens that could crash the market.
                                <br /><br />
                                2. *Innovative Use of Flare Network*: The DAX project utilizes the Flare Network&apos;s unique features, such as FlareDrops and a liquidity pool (WFLR/DAX), to create a sustainable ecosystem. The Lotto Pool, funded by FlareDrops, adds an additional layer of value without relying on user funds.
                                <br /><br />
                                3. *Transparent and Fair Lottery System*: DAX offers a fully developed lottery (6 out of 49) where users can stake $10 worth of DAX tokens for 30 days. This staking mechanism ensures that tokens are temporarily removed from circulation, helping stabilize the market. The lottery is transparent, conducted via smart contracts, and the winnings are distributed in WFLR, protecting the DAX token from sudden sell-offs.
                                <br /><br />
                                4. *NFT Integration*: DAX incorporates NFTs that enhance lottery winnings. These NFTs are sold for $FLR and $WFLR, with 50% of the proceeds used for buy and burn, further reducing the total supply of DAX tokens.
                                <br /><br />
                                5. *Burn Rate Mechanism*: A 2% burn rate is applied to every transaction, including transfers and staking, which continually decreases the total supply of DAX tokens, creating a deflationary effect.
                                <br /><br />
                                Overall, DAX’s strategic tokenomics, use of the Flare Network, transparent lottery system, and deflationary mechanisms provide a robust and sustainable framework that addresses the common pitfalls of other memecoins.
                            </Typography>
                        </Collapse>
                    </Box>


                    <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 6 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                <div>
                                    <MuiTooltip title="Kopieren">
                                        <IconButton size="small" onClick={handleCopyClick}>
                                            <FileCopyIcon />
                                        </IconButton>
                                    </MuiTooltip>
                                    <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                                        {contract_address.substring(0, 4)}...{contract_address.substring(contract_address.length - 4)}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={4} container justifyContent="flex-end">
                                <ButtonGroup variant="text" color="inherit" aria-label="button group">
                                    <Button
                                        size="small"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={'Dax Token - Flare Explorer'}
                                        href="https://flare-explorer.flare.network/address/0xDE373AE127A11E756A9D1cc7743816928B239283"
                                    >
                                        <Box sx={{ filter: theme.palette.mode === 'light' ? 'invert(0%)' : 'invert(100%)' }}>
                                            <Image
                                                src="/flare_icon.svg"
                                                alt="Flare Logo"
                                                width={20}
                                                height={20}
                                            />
                                        </Box>
                                    </Button>

                                    <Button
                                        size="small"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={'Dax Token - BlazeSwap Swap'}
                                        href="https://app.blazeswap.xyz/swap/?inputCurrency=NAT&outputCurrency=0xDE373AE127A11E756A9D1cc7743816928B239283"
                                    >
                                        <Image
                                            src="/blazeswap_icon.svg"
                                            alt="BlazeSwap Logo"
                                            width={20}
                                            height={20}
                                        />
                                    </Button>

                                    <Button
                                        size="small"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={'Dax Token - GeckoTerminal'}
                                        href="https://www.geckoterminal.com/de/flare/pools/0xb2868e9fef2b5efb4aa4853a539a6ba26177b8c3"
                                    >
                                        <Image
                                            src="/geckoterminal_icon.svg"
                                            alt="Geckoterminal Logo"
                                            width={20}
                                            height={20}
                                        />
                                    </Button>
                                    <Button
                                        size="small"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title={'Dax Token - DexTools'}
                                        href="https://www.dextools.io/app/en/flare/pair-explorer/0xb2868e9fef2b5efb4aa4853a539a6ba26177b8c3?t=1719418049473"
                                    >
                                        <Image
                                            src="/DEXTools.png"
                                            alt="DEXTools Logo"
                                            width={20}
                                            height={20}
                                        />
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />

                    <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Token Name</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem',
                                            sm: '1.2rem',
                                            md: '1.5rem',
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {projectData?.tokenData.tokenName !== null ? (
                                            <>{projectData?.tokenData.tokenName}</>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Token Symbol</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {projectData?.tokenData.tokenSymbol !== null ? (
                                            <>{projectData?.tokenData.tokenSymbol}</>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Decimals</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {projectData?.tokenData.tokenDecimals !== null ? (
                                            <>{projectData?.tokenData.tokenDecimals}</>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Blockchain</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>FLR Chain (14)</Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Total Supply</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {projectData?.tokenData.totalSupplyFormatted !== null ? (
                                            <>
                                                {projectData?.tokenData.totalSupplyFormatted} DAX
                                            </>

                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Box>
                                    <Typography variant="body1">Burn Rate</Typography>
                                    <Typography variant="body2" sx={{
                                        fontSize: {
                                            xs: '1rem', // Für mobile Geräte
                                            sm: '1.2rem', // Für kleine Bildschirme
                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                        },
                                        fontFamily: 'Arial, sans-serif',
                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                        fontWeight: 'bold',
                                    }}>
                                        {projectData?.tokenData.tokenBurnRate !== null ? (
                                            <>{projectData?.tokenData.tokenBurnRate}%</>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%" // Optional, if you want it to be vertically centered as well
                                >
                                    <Image
                                        src="/tokenomics.png"
                                        alt="Flare Dax Tokenomics"
                                        width={250}
                                        height={250}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6}>
                                        <Box>
                                            <Typography variant="body1">Total Burned</Typography>
                                            <Typography variant="body2" sx={{
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1.2rem',
                                                    md: '1.5rem',
                                                },
                                                fontFamily: 'Arial, sans-serif',
                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                fontWeight: 'bold',
                                            }}>
                                                <>
                                                    {projectData?.tokenData.burnedSupplyFormatted !== null ? (
                                                        <>{projectData?.tokenData.burnedSupplyFormatted} DAX</>
                                                    ) : (
                                                        <>Loading...</>
                                                    )}
                                                    </>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Total Burned in Percent</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem', // Für mobile Geräte
                                                sm: '1.2rem', // Für kleine Bildschirme
                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {projectData?.tokenData.burnedSupplyPercentFormatted !== null ? (
                                                    <>{projectData?.tokenData.burnedSupplyPercentFormatted}%</>
                                                ) : (
                                                    <>Loading...</>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <Box>
                                            <Typography variant="body1">Circulating Supply</Typography>
                                            <Typography variant="body2" sx={{
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1.2rem',
                                                    md: '1.5rem',
                                                },
                                                fontFamily: 'Arial, sans-serif',
                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                fontWeight: 'bold',
                                            }}>
                                                <>

                                                    {projectData?.tokenData.total_circulating !== null ? (
                                                        <>{formatDaxSum(projectData?.tokenData.total_circulating)}</>
                                                    ) : (
                                                        <>Loading...</>
                                                    )}
                                                </>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Box>
                                            <Typography variant="body1">Blocked Stock</Typography>
                                            <Typography variant="body2" sx={{
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1.2rem',
                                                    md: '1.5rem',
                                                },
                                                fontFamily: 'Arial, sans-serif',
                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                fontWeight: 'bold',
                                            }}>
                                                <>
                                                    2.500.000.000
                                                </>
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Last Buy and Burn</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem',
                                                sm: '1.2rem',
                                                md: '1.5rem',
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {projectData?.burnLogData.time ? (
                                                    <>{formatDate(projectData?.burnLogData.time)}</>
                                                ) : (
                                                    <p>Lade Daten...</p>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Burned</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem',
                                                sm: '1.2rem',
                                                md: '1.5rem',
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {projectData?.burnLogData.burnsum ? (
                                                    <>
                                                        {formatDaxSum(projectData?.burnLogData.burnsum)}
                                                    </>
                                                ) : (
                                                    <Box>Lade Daten...</Box>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Box>

                    <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6}>
                                        <Box>
                                            <Typography variant="body1">Pool Volume</Typography>
                                            <Typography variant="body2" sx={{
                                                fontSize: {
                                                    xs: '1rem',
                                                    sm: '1.2rem',
                                                    md: '1.5rem',
                                                },
                                                fontFamily: 'Arial, sans-serif',
                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                fontWeight: 'bold',
                                            }}>
                                                <>
                                                    {projectData?.extendedLotteryData.pool_volume ? (
                                                        <>
                                                            {formatWflrSum(projectData?.extendedLotteryData.pool_volume)}
                                                        </>
                                                    ) : (
                                                        <Box>Lade Daten...</Box>
                                                    )}

                                                </>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Total Active Lottery Tickets</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem', // Für mobile Geräte
                                                sm: '1.2rem', // Für kleine Bildschirme
                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {projectData?.extendedLotteryData.ActiveLotteryTickets ? (
                                                    <>
                                                        {projectData?.extendedLotteryData.ActiveLotteryTickets}
                                                    </>
                                                ) : (
                                                    <Box>Lade Daten...</Box>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Total Lottery Staking Volume</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem', // Für mobile Geräte
                                                sm: '1.2rem', // Für kleine Bildschirme
                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {projectData?.extendedLotteryData.LotteryStarkingVolumeFormatted ? (
                                                    <>
                                                        {projectData?.extendedLotteryData.LotteryStarkingVolumeFormatted} DAX
                                                    </>
                                                ) : (
                                                    <Box>Lade Daten...</Box>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Next Draw</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem', // Für mobile Geräte
                                                sm: '1.2rem', // Für kleine Bildschirme
                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {projectData?.extendedLotteryData.next_draw ? (
                                                    <>
                                                        {formatUnixTimestampToUTC(projectData?.extendedLotteryData.next_draw)}
                                                    </>
                                                ) : (
                                                    <Box>Lade Daten...</Box>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%" // Optional, if you want it to be vertically centered as well
                                >
                                    <Image
                                        src="/lottery01.png"
                                        alt="Flare Dax Lottery"
                                        width={250}
                                        height={250}
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>

                    <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Last Draw Period</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem', // Für mobile Geräte
                                                sm: '1.2rem', // Für kleine Bildschirme
                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {projectData?.lotteryPeriodData.period ? (
                                                    <>
                                                        {projectData?.lotteryPeriodData.period}
                                                    </>
                                                ) : (
                                                    <Box>Lade Daten...</Box>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1">Last Drow Numbers</Typography>
                                        <Typography variant="body2" sx={{
                                            fontSize: {
                                                xs: '1rem', // Für mobile Geräte
                                                sm: '1.2rem', // Für kleine Bildschirme
                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                            },
                                            fontFamily: 'Arial, sans-serif',
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            fontWeight: 'bold',
                                        }}>
                                            <>
                                                {projectData?.lotteryPeriodData.nummbers ? (
                                                    <>
                                                        {projectData?.lotteryPeriodData.nummbers}
                                                    </>
                                                ) : (
                                                    <Box>Lade Daten...</Box>
                                                )}
                                            </>
                                        </Typography>

                                    </Grid>

                                    {projectData?.lotteryPeriodData &&
                                        (projectData.lotteryPeriodData["3_current_numbers"] > 0 || projectData.lotteryPeriodData["3_current_numbers"] === null) && (
                                            <>
                                            <Grid item xs={6} md={6}>
                                                <Typography variant="body1">Tickets with 3 Matches</Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontSize: {
                                                            xs: '1rem', // Für mobile Geräte
                                                            sm: '1.2rem', // Für kleine Bildschirme
                                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                                        },
                                                        fontFamily: 'Arial, sans-serif',
                                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    <>{projectData.lotteryPeriodData["3_current_numbers"]}</>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <Typography variant="body1">Winning Sum</Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        fontSize: {
                                                            xs: '1rem', // Für mobile Geräte
                                                            sm: '1.2rem', // Für kleine Bildschirme
                                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                                        },
                                                        fontFamily: 'Arial, sans-serif',
                                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {projectData?.lotteryPeriodData["2_procent"] !== null ? (
                                                        <Box>
                                                            <Typography variant="body2" sx={{
                                                                fontSize: {
                                                                    xs: '1rem', // Für mobile Geräte
                                                                    sm: '1.2rem', // Für kleine Bildschirme
                                                                    md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                },
                                                                fontFamily: 'Arial, sans-serif',
                                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                fontWeight: 'bold',
                                                            }}>
                                                                {formatWflrSum(projectData.lotteryPeriodData["2_procent"])} Total
                                                            </Typography>
                                                            <Typography variant="body2" sx={{
                                                                fontSize: {
                                                                    xs: '1rem', // Für mobile Geräte
                                                                    sm: '1.2rem', // Für kleine Bildschirme
                                                                    md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                },
                                                                fontFamily: 'Arial, sans-serif',
                                                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                fontWeight: 'bold',
                                                            }}>
                                                                <>
                                                                    <Typography variant="body2" sx={{
                                                                        fontSize: {
                                                                            xs: '1rem', // Für mobile Geräte
                                                                            sm: '1.2rem', // Für kleine Bildschirme
                                                                            md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                        },
                                                                        fontFamily: 'Arial, sans-serif',
                                                                        color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                        fontWeight: 'bold',
                                                                    }}>
                                                                        {formatWflrSum(String(Number(projectData.lotteryPeriodData["2_procent"]) / Number(projectData.lotteryPeriodData["3_current_numbers"])))} per ticket
                                                                    </Typography>
                                                                </>
                                                            </Typography>
                                                        </Box>
                                                    ) : (
                                                        <Box>Lade Daten...</Box>
                                                    )}
                                                </Typography>
                                            </Grid>
                                            </>
                                        )}

                                    {projectData?.lotteryPeriodData &&
                                        (projectData.lotteryPeriodData["4_current_numbers"] > 0 || projectData.lotteryPeriodData["4_current_numbers"] === null) && (
                                            <>
                                                <Grid item xs={6} md={6}>
                                                    <Typography variant="body1">Tickets with 4 Matches</Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: {
                                                                xs: '1rem', // Für mobile Geräte
                                                                sm: '1.2rem', // Für kleine Bildschirme
                                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                                            },
                                                            fontFamily: 'Arial, sans-serif',
                                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        <>{projectData.lotteryPeriodData["4_current_numbers"]}</>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <Typography variant="body1">Winning Sum</Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: {
                                                                xs: '1rem', // Für mobile Geräte
                                                                sm: '1.2rem', // Für kleine Bildschirme
                                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                                            },
                                                            fontFamily: 'Arial, sans-serif',
                                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {projectData?.lotteryPeriodData["4_procent"] !== null ? (
                                                            <Box>
                                                                <Typography variant="body2" sx={{
                                                                    fontSize: {
                                                                        xs: '1rem', // Für mobile Geräte
                                                                        sm: '1.2rem', // Für kleine Bildschirme
                                                                        md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                    },
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                    {formatWflrSum(projectData.lotteryPeriodData["4_procent"])} Total
                                                                </Typography>
                                                                <Typography variant="body2" sx={{
                                                                    fontSize: {
                                                                        xs: '1rem', // Für mobile Geräte
                                                                        sm: '1.2rem', // Für kleine Bildschirme
                                                                        md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                    },
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                    <>
                                                                        <Typography variant="body2" sx={{
                                                                            fontSize: {
                                                                                xs: '1rem', // Für mobile Geräte
                                                                                sm: '1.2rem', // Für kleine Bildschirme
                                                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                            },
                                                                            fontFamily: 'Arial, sans-serif',
                                                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                            fontWeight: 'bold',
                                                                        }}>
                                                                            {formatWflrSum(String(Number(projectData.lotteryPeriodData["4_procent"]) / Number(projectData.lotteryPeriodData["4_current_numbers"])))} per ticket
                                                                        </Typography>
                                                                    </>
                                                                </Typography>
                                                            </Box>
                                                        ) : (
                                                            <Box>Lade Daten...</Box>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}

                                    {projectData?.lotteryPeriodData &&
                                        (projectData.lotteryPeriodData["5_current_numbers"] > 0 || projectData.lotteryPeriodData["5_current_numbers"] === null) && (
                                            <>
                                                <Grid item xs={6} md={6}>
                                                    <Typography variant="body1">Tickets with 5 Matches</Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: {
                                                                xs: '1rem', // Für mobile Geräte
                                                                sm: '1.2rem', // Für kleine Bildschirme
                                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                                            },
                                                            fontFamily: 'Arial, sans-serif',
                                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        <>{projectData.lotteryPeriodData["5_current_numbers"]}</>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <Typography variant="body1">Winning Sum</Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: {
                                                                xs: '1rem', // Für mobile Geräte
                                                                sm: '1.2rem', // Für kleine Bildschirme
                                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                                            },
                                                            fontFamily: 'Arial, sans-serif',
                                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {projectData?.lotteryPeriodData["14_procent"] !== null ? (
                                                            <Box>
                                                                <Typography variant="body2" sx={{
                                                                    fontSize: {
                                                                        xs: '1rem', // Für mobile Geräte
                                                                        sm: '1.2rem', // Für kleine Bildschirme
                                                                        md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                    },
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                    {formatWflrSum(projectData.lotteryPeriodData["14_procent"])} Total
                                                                </Typography>
                                                                <Typography variant="body2" sx={{
                                                                    fontSize: {
                                                                        xs: '1rem', // Für mobile Geräte
                                                                        sm: '1.2rem', // Für kleine Bildschirme
                                                                        md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                    },
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                    <>
                                                                        <Typography variant="body2" sx={{
                                                                            fontSize: {
                                                                                xs: '1rem', // Für mobile Geräte
                                                                                sm: '1.2rem', // Für kleine Bildschirme
                                                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                            },
                                                                            fontFamily: 'Arial, sans-serif',
                                                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                            fontWeight: 'bold',
                                                                        }}>
                                                                            {formatWflrSum(String(Number(projectData.lotteryPeriodData["14_procent"]) / Number(projectData.lotteryPeriodData["5_current_numbers"])))} per ticket
                                                                        </Typography>
                                                                    </>
                                                                </Typography>
                                                            </Box>
                                                        ) : (
                                                            <Box>Lade Daten...</Box>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}

                                    {projectData?.lotteryPeriodData &&
                                        (projectData.lotteryPeriodData["6_current_numbers"] > 0 || projectData.lotteryPeriodData["6_current_numbers"] === null) && (
                                            <>
                                                <Grid item xs={6} md={6}>
                                                    <Typography variant="body1">Tickets with 6 Matches</Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: {
                                                                xs: '1rem', // Für mobile Geräte
                                                                sm: '1.2rem', // Für kleine Bildschirme
                                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                                            },
                                                            fontFamily: 'Arial, sans-serif',
                                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        <>{projectData.lotteryPeriodData["6_current_numbers"]}</>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <Typography variant="body1">Winning Sum</Typography>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            fontSize: {
                                                                xs: '1rem', // Für mobile Geräte
                                                                sm: '1.2rem', // Für kleine Bildschirme
                                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                                            },
                                                            fontFamily: 'Arial, sans-serif',
                                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {projectData?.lotteryPeriodData["60_procent"] !== null ? (
                                                            <Box>
                                                                <Typography variant="body2" sx={{
                                                                    fontSize: {
                                                                        xs: '1rem', // Für mobile Geräte
                                                                        sm: '1.2rem', // Für kleine Bildschirme
                                                                        md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                    },
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                    {formatWflrSum(projectData.lotteryPeriodData["60_procent"])} Total
                                                                </Typography>
                                                                <Typography variant="body2" sx={{
                                                                    fontSize: {
                                                                        xs: '1rem', // Für mobile Geräte
                                                                        sm: '1.2rem', // Für kleine Bildschirme
                                                                        md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                    },
                                                                    fontFamily: 'Arial, sans-serif',
                                                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                    <>
                                                                        <Typography variant="body2" sx={{
                                                                            fontSize: {
                                                                                xs: '1rem', // Für mobile Geräte
                                                                                sm: '1.2rem', // Für kleine Bildschirme
                                                                                md: '1.5rem', // Für mittlere und größere Bildschirme
                                                                            },
                                                                            fontFamily: 'Arial, sans-serif',
                                                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                                            fontWeight: 'bold',
                                                                        }}>
                                                                            {formatWflrSum(String(Number(projectData.lotteryPeriodData["60_procent"]) / Number(projectData.lotteryPeriodData["6_current_numbers"])))} per ticket
                                                                        </Typography>
                                                                    </>
                                                                </Typography>
                                                            </Box>
                                                        ) : (
                                                            <Box>Lade Daten...</Box>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%" // Optional, if you want it to be vertically centered as well
                                >
                                    <Image
                                        src="/lottery02.png"
                                        alt="Flare Dax Lottery"
                                        width={250}
                                        height={250}
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

            </Container>

        </Box>
            <Divider />
            <Box
                id="lottery"
                sx={{
                    pt: { xs: 4, sm: 12 },
                    pb: { xs: 8, sm: 16 },
                }}
            >
                <Container
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: { xs: 3, sm: 6 },
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', md: '60%' },
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h2" variant="h2" color="text.primary">
                            Lottery
                        </Typography>

                        <Typography variant="body1" color="text.secondary" sx={{ py: 2 }}>
                            Winning numbers are drawn every 15 days.
                        </Typography>

                        <Typography component="h3" variant="h3" color="text.primary">
                            Lottery Pool Volume
                        </Typography>
                        <Divider />
                        <Typography component="h4" variant="h1" color="text.primary">
                            <AnimatedNumber value={Number(Math.floor(Number(projectData?.extendedLotteryData.pool_volume)))} /> WFLR
                        </Typography>

                        <Divider />
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <ResponsiveContainer width="100%" height={400}>
                                <PieChart>
                                    <Pie
                                        data={LotteryDataArray}
                                        dataKey="value"
                                        nameKey="label"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={100}
                                        label={({ name, value }: { name: string; value: number }) => `${value}%`}
                                    >
                                        {LotteryDataArray.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={LotteryDataCOLORS[index % LotteryDataCOLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<CustomTooltip />} /> {/* Verwende benutzerdefinierten Tooltip */}
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2, // This adds spacing between the buttons
                            }}
                        >
                            <Button variant="contained" color="secondary" size="large" href={'/lottery/guide/'}>Lottery Guide</Button>
                            <Button variant="contained" color="secondary" size="large" href={'/lottery/mechanisms/'}>Lottery Mechanisms</Button>

                        </Box>
                    </Box>
                </Container>
            </Box>
        </ClientOnly>
    );
};

export default Wilcome;